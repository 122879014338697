<template>
  <div class="row table-wrap">
    <div class="column">
      <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>

      <div class="columns with-right-space">
        <p
          v-if="!uiFlags.isFetching && ((Array.isArray(attributes) && !attributes.length) || (!Array.isArray(attributes) && !attributes))"
          class="no-items-error-message"
        >
          {{ $t('ATTRIBUTES_MGMT.LIST.EMPTY_RESULT.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('ATTRIBUTES_MGMT.LOADING')"
        />
        <table
          v-if="!uiFlags.isFetching && attributes.length"
          class="woot-table"
        >
          <thead v-if="selectedTabIndex !== 2">
            <th
              v-for="tableHeader in $t('ATTRIBUTES_MGMT.LIST.TABLE_HEADER')"
              :key="tableHeader"
              class="item"
            >
              {{ tableHeader }}
            </th>
          </thead>

          <thead v-if="selectedTabIndex === 2">
            <th
              class="item"
            >
              {{ attributes[0].company.name }}
            </th>
            <th
              class="item"
            >
              {{ attributes[0].client_code.name }}
            </th>
            <th
              class="item"
            >
              {{ attributes[0].status.name }}
            </th>
            <th
              class="item"
            >
              {{ attributes[0].monthly_fee.name }}
            </th>
            <th
              class="item"
              v-if="attributes[0].custom_field_first.name"
            >
              {{ attributes[0].custom_field_first.name }}
            </th>
            <th
              class="item"
              v-if="attributes[0].custom_field_second.name"
            >
              {{ attributes[0].custom_field_second.name }}
            </th>
            <woot-button
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT')"
                  variant="clear"
                  size="large"
                  color-scheme="hollow"
                  class-names="grey-btn"
                  :icon="showData ? 'eye-hide' : 'eye-show'"
                  @click="handleVisibility()"
                />
          </thead>
          <tbody v-if="selectedTabIndex !== 2">
            <tr v-for="attribute in attributes" :key="attribute.attribute_key">
              <td class="item text-truncate">
                {{ attribute.attribute_display_name }}
              </td>
              <td class="item-description text-truncate">
                {{ attribute.attribute_description }}
              </td>
              <td class="item text-truncatee">
                {{ attribute.attribute_display_type }}
              </td>
              <td class="item key text-truncate">
                {{ attribute.attribute_key }}
              </td>
              <td class="button-wrapper">
                <woot-button
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  icon="edit"
                  @click="openEditPopup(attribute)"
                />
                <woot-button
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  @click="openDelete(attribute)"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="attribute in attributes[0].group_attributes" :key="attribute.id">
              <td class="item text-truncate">
                {{ attributes[0].company.visibility || showData ? attribute.company : "***" }}
              </td>
              <td class="item-description text-truncate">
                {{ attributes[0].client_code.visibility || showData ? attribute.client_code : "***" }}
              </td>
              <td class="item text-truncatee">
                {{ attributes[0].status.visibility || showData ? attribute.status : "***" }}
              </td>
              <td class="item key text-truncate">
                {{ attributes[0].monthly_fee.visibility || showData ? attribute.monthly_fee : "***" }}
              </td>
              <td class="item key text-truncate" v-if="attributes[0].custom_field_first.name">
                {{ attributes[0].custom_field_first.visibility || showData ? attribute.custom_field_first : "***" }}
              </td>
              <td class="item key text-truncate" v-if="attributes[0].custom_field_second.name">
                {{ attributes[0].custom_field_second.visibility || showData ? attribute.custom_field_second : "***" }}
              </td>
              <td class="button-wrapper">
                <woot-button v-if="currentUser.permissions.group_attributes || currentUser.type_role === 'administrator'"
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  icon="edit"
                  @click="openEditGroupPopup(attribute)"
                />
                <woot-button v-if="currentUser.permissions.group_attributes || currentUser.type_role === 'administrator'"
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  @click="openDeleteGroup(attribute)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="small-4 columns">
      <span v-dompurify-html="$t('ATTRIBUTES_MGMT.SIDEBAR_TXT')" />
      <div v-if="selectedTabIndex === 2 && attributes.length && (currentUser.permissions.group_attributes || currentUser.type_role === 'administrator')">
            <woot-button @click="openEditTablePopup"
            >{{$t('ATTRIBUTES_MGMT.EDIT_TABLE')}}
          </woot-button>
          </div>
    </div>
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-attribute
        :selected-attribute="selectedAttribute"
        :is-updating="uiFlags.isUpdating"
        @on-close="hideEditPopup"
      />
    </woot-modal>
    <woot-modal :show.sync="showEditTablePopup" :on-close="hideEditTablePopup">
      <edit-table
        :selected-table="attributes[0]"
        @on-close="hideEditTablePopup"
      />
    </woot-modal>
    <woot-modal :show.sync="showEditGroupPopup" :on-close="hideEditGroupPopup">
      <edit-group-attribute
        :selected-attribute="selectedAttribute"
        :selected-table="attributes[0]"
        :is-updating="uiFlags.isUpdating"
        @on-close="hideEditGroupPopup"
      />
    </woot-modal>
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="confirmDeleteTitle"
      :message="$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedAttribute.attribute_display_name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
    <woot-confirm-delete-modal
      v-if="showDeleteGroupPopup"
      :show.sync="showDeleteGroupPopup"
      :title="confirmGroupDeleteTitle"
      :message="$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteGroupConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedAttribute.company"
      :confirm-place-holder-text="confirmGroupPlaceHolderText"
      @on-confirm="confirmGroupDeletion"
      @on-close="closeDeleteGroup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import EditAttribute from './EditAttribute';
import EditGroupAttribute from './EditGroupAttribute.vue';
import EditTable from './EditTable.vue';
import WootButton from '../../../../components/ui/WootButton.vue';
import attributes from '../../../../api/attributes';


export default {
  components: {
    EditAttribute,
    EditGroupAttribute,
    EditTable,
    WootButton,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedTabIndex: 0,
      showEditPopup: false,
      showEditTablePopup: false,
      showEditGroupPopup: false,
      showDeletePopup: false,
      showDeleteGroupPopup: false,
      selectedAttribute: {},
      showData:false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'attributes/getUIFlags',
      currentUser: 'getCurrentUser',
    }),
    attributes() {
      let attributeModel = '';
        
        if(this.selectedTabIndex === 0){
          attributeModel = 'conversation_attribute';
        }else if(this.selectedTabIndex === 1){
          attributeModel = 'contact_attribute';
        }else {
          attributeModel = 'group_attribute';
        }
        const customAttribute = this.$store.getters['attributes/getAttributesByModel'](
          attributeModel
        );
        return customAttribute
      },
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('ATTRIBUTES_MGMT.TABS.CONVERSATION'),
        },
        {
          key: 1,
          name: this.$t('ATTRIBUTES_MGMT.TABS.CONTACT'),
        },
        {
          key: 2,
          name: 'Grupos',
        },
      ];
    },
    deleteConfirmText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedAttribute.attribute_display_name
      }`;
    },
    deleteGroupConfirmText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedAttribute?.company
      }`;
    },
    deleteRejectText() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.NO');
    },
    confirmDeleteTitle() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.TITLE', {
        attributeName: this.selectedAttribute.attribute_display_name,
      });
    },
    confirmGroupDeleteTitle() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.TITLE', {
        attributeName: this.selectedAttribute?.company,
      });
    },
    confirmPlaceHolderText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        attributeName: this.selectedAttribute.attribute_display_name,
      })}`;
    },
    confirmGroupPlaceHolderText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        attributeName: this.selectedAttribute?.company,
      })}`;
    },
  },
  mounted() {
    this.fetchAttributes(this.selectedTabIndex);
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
      this.$store.dispatch('attributes/setTab', index);
      this.fetchAttributes(index);
    },
    handleVisibility(){
      if(this.currentUser?.permissions?.group_attributes || this.currentUser?.type_role === 'administrator'){
        this.showData = !this.showData
      }
    },
    fetchAttributes(index) {
      this.$store.dispatch('attributes/get', index);
    },
    async deleteAttributes({ id }) {
      try {
        await this.$store.dispatch('attributes/delete', id);
        this.showAlert(this.$t('ATTRIBUTES_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('ATTRIBUTES_MGMT.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async deleteGroupAttributes({ id }) {
      try {
        await this.$store.dispatch('attributes/delete',{id: id, attributeType: 'group_attribute'});
        this.showAlert(this.$t('ATTRIBUTES_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        this.fetchAttributes(this.selectedTabIndex);
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('ATTRIBUTES_MGMT.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedAttribute = response;
    },
    openEditTablePopup() {
      this.showEditTablePopup = true;
    },
    hideEditTablePopup() {
      this.showEditTablePopup = false; 
    },
    hideEditPopup() {
      this.showEditPopup = false; 
    },
    openEditGroupPopup(response) {
      this.showEditGroupPopup = true;
      this.selectedAttribute = response;
    },
    hideEditGroupPopup() {
      this.showEditGroupPopup = false; 
    },
    confirmDeletion() {
      this.deleteAttributes(this.selectedAttribute);
      this.closeDelete();
    },
    confirmGroupDeletion() {
      this.deleteGroupAttributes(this.selectedAttribute);
      this.closeDeleteGroup();
    },
    openDelete(value) {
      this.showDeletePopup = true;
      this.selectedAttribute = value;
    },
    openDeleteGroup(value) {
      this.showDeleteGroupPopup = true;
      this.selectedAttribute = value;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedAttribute = {};
    },
    closeDeleteGroup() {
      this.showDeleteGroupPopup = false;
      this.selectedAttribute = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrap {
  padding-left: var(--space-small);
}

.woot-table {
  width: 100%;
  margin-top: var(--space-small);
  table-layout: fixed;
}

.no-items-error-message {
  margin-top: var(--space-larger);
}

.tabs {
  padding-left: 0;
  margin-right: var(--space-medium);
  user-select: none;
}

.item {
  padding-left: 0;
  max-width: 10rem;
  min-width: 8rem;
}

.item-description {
  padding-left: 0;
  max-width: 16rem;
  min-width: 10rem;
}

.key {
  font-family: monospace;
}

::v-deep {
  .tabs-title a {
    font-weight: var(--font-weight-medium);
    padding-top: 0;
  }
}
</style>
