<template>
    <woot-modal :show.sync="show" :on-close="onClose">
      <div class="column content-box">
        <woot-modal-header :header-title="$t('ATTRIBUTES_MGMT.ADD.TITLE')" />
        
        <div>
          <div class="main-form">
  
            <div>
              <span class="span-label">
                <form @submit.prevent="addTable">
                  <woot-input class="input-text" v-model.trim="addTableComponents.name"  :error="$v.addTableComponents.name.$error
                ? $t('ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR')
                : ''
                " :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.NAME')"
                      type="text" :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.NAME')" />
                  <div class="field">
                    <woot-input class="input-text" v-model.trim="addTableComponents.company.name" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.COMPANY')"
                      type="text" :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.COMPANY')" />
                    <div class="block">
                      <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY') }}</label>
                      <input class="checkbox" v-model.trim="addTableComponents.company.visibility" type="checkbox" />
                    </div>
                  </div>
                  <div class="field">
                    <woot-input class="input-text" v-model.trim="addTableComponents.client_code.name" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CLIENT_CODE')"
                      type="text" :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CLIENT_CODE')" />
                    <div class="block">
                      <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY')  }}</label>
                      <input class="checkbox" v-model.trim="addTableComponents.client_code.visibility" type="checkbox" />
                    </div>
                  </div>
                  <div class="field">
                    <woot-input class="input-text" v-model.trim="addTableComponents.status.name" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.STATUS')"
                      type="text" :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.STATUS')" />
                    <div class="block">
                      <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY')  }}</label>
                      <input class="checkbox" v-model.trim="addTableComponents.status.visibility" type="checkbox" />
                    </div>
                  </div>
                  <div class="field">
                    <woot-input class="input-text" v-model.trim="addTableComponents.monthly_fee.name" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.MONTHLY_FEE')"
                      type="text" :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.MONTHLY_FEE')" />
                    <div class="block">
                      <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY')  }}</label>
                      <input class="checkbox" v-model.trim="addTableComponents.monthly_fee.visibility" type="checkbox" />
                    </div>
                  </div>
                  <div class="field">
                    <woot-input class="input-text" v-model.trim="addTableComponents.custom_field_first.name" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_ONE')"
                      type="text" :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_ONE')" />
                    <div class="block">
                      <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY')  }}</label>
                      <input class="checkbox" v-model.trim="addTableComponents.custom_field_first.visibility" type="checkbox" />
                    </div>
                  </div>
                  <div class="field">
                    <woot-input class="input-text" v-model.trim="addTableComponents.custom_field_second.name" :label="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_TWO')"
                      type="text" :placeholder="$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CUSTOM_TWO')" />
                    <div class="block">
                      <label>{{ $t('CUSTOM_ATTRIBUTES.FORM.VISIBILITY')  }}</label>
                      <input class="checkbox" v-model.trim="addTableComponents.custom_field_second.visibility" type="checkbox" />
                    </div>
                  </div>
                  <woot-button>
                  {{ $t('CUSTOM_ATTRIBUTES.FORM.CREATE') }}
                </woot-button>
                <woot-button variant="clear" @click.prevent="onClose">
                  {{ $t('CUSTOM_ATTRIBUTES.FORM.CANCEL') }}
                </woot-button>
                </form>
              </span>
              <div class="modal-footer">
                
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </woot-modal>
  </template>
  
  <script>
  import { required, minLength } from 'vuelidate/lib/validators';
  import { mapGetters } from 'vuex';
  import alertMixin from 'shared/mixins/alertMixin';
  
  export default {
    mixins: [alertMixin],
    data() {
      return {
        values: [],
        options: [],
        show: true,
        isTouched: false,
        addTableComponents: {
          name: '',
          company: {
            name: this.$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.COMPANY'),
            visibility: true
          },
          client_code: {
            name: this.$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.CLIENT_CODE'),
            visibility: true
          },
          status: {
            name: this.$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.STATUS'),
            visibility: true
          },
          monthly_fee: {
            name: this.$t('CUSTOM_ATTRIBUTES.FORM.DEFAULT_VALUES.MONTHLY_FEE'),
            visibility: true
          },
          custom_field_first: {
            name: '',
            visibility: true
          },
          custom_field_second: {
            name: '',
            visibility: true
          },
        }
      };
    },
  
    mounted() {
      console.log("souza box1", this.indexTab)
    },
  
    computed: {
      ...mapGetters({
        uiFlags: 'getUIFlags',
        indexTab: 'attributes/getIndexTabAttributes',
      }),
      groupAttributes() {
        const customAttribute = this.$store.getters['attributes/getAttributesByModel'](
          'group_attribute'
        );
        console.log(customAttribute)
        return customAttribute
      },
    },
  
  
    validations: {
      addTableComponents: {
        name:{
          required,
          minLength: minLength(1),
        },
        company: {}
        
      }
    },
  
    methods: {

      onClose(){
        this.$emit('on-close')
      },
      onTouch() {
        this.isTouched = true;
      },
      async addTable() {
        try {
        await this.$store.dispatch('attributes/create', {
          attributeType: 'group_table',
          name: this.addTableComponents.name,
          company: this.addTableComponents.company || {},
          client_code: this.addTableComponents.client_code || {},
          status: this.addTableComponents.status || {},
          monthly_fee: this.addTableComponents.monthly_fee || {},
          custom_field_first: this.addTableComponents.custom_field_first || {},
          custom_field_second: this.addTableComponents.custom_field_second || {},
        });
        this.onClose
      } catch (error) {
        console.error(`Erro ao processar`, error);
        throw error;
      }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  
  .field {
    display: flex;
    gap: 10px; 
  }
  
  .field .input-text {
    flex-grow: 1; 
  }
  
  .checkbox-container {
    display: flex;
    flex-direction: column; 
    gap: 5px; 
  }
  
  .checkbox {
    margin-top: 15px;
    margin-left: 5px;
    transform: scale(1.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  ::v-deep {
    .multiselect {
      margin-bottom: 0;
    }
  
    .multiselect__content-wrapper {
      display: none;
    }
  
    .multiselect--active .multiselect__tags {
      border-radius: var(--border-radius-normal);
    }
  
    .tabs {
      padding: 0;
    }
  
  }
  
  
  .main-form {
    margin-top: 1rem;
  }
  
  .span-label {
    display: block;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8;
    color: #3c4858;
  }
  </style>
  