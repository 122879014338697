<template>
  <div class="column content-box">
    <woot-modal-header :header-title="pageTitle" />
    <form class="row" @submit.prevent="editAttributes">
      <div class="medium-12 columns">
        <woot-input
          v-model.trim="companyName"
          :label="selectedTable.company.name"
          type="text"
          :class="{ error: $v.companyName.$error }"
          :error="
            $v.companyName.$error
              ? $t('ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR')
              : ''
          "
          :placeholder="selectedTable.company.name"
          @blur="$v.companyName.$touch"
        />
        <woot-input
          v-model.trim="clientCode"
          :label="selectedTable.client_code.name"
          type="text"
          :class="{ error: $v.clientCode.$error }"
          :error="$v.clientCode.$error ? keyErrorMessage : ''"
          :placeholder="selectedTable.client_code.name"
          @blur="$v.clientCode.$touch"
        />
        <woot-input
          v-model.trim="status"
          :label="selectedTable.status.name"
          type="text"
          :class="{ error: $v.status.$error }"
          :error="$v.status.$error ? keyErrorMessage : ''"
          :placeholder="selectedTable.status.name"
          @blur="$v.status.$touch"
        />
        <woot-input
          v-model.trim="monthlyFee"
          :label="selectedTable.monthly_fee.name"
          type="text"
          :class="{ error: $v.monthlyFee.$error }"
          :error="$v.monthlyFee.$error ? keyErrorMessage : ''"
          :placeholder="selectedTable.monthly_fee.name"
          @blur="$v.monthlyFee.$touch"
        />
        <woot-input v-if="selectedTable.custom_field_second.name"
          v-model.trim="firstCustom"
          :label="selectedTable.custom_field_first.name"
          type="text"
          :class="{ error: $v.firstCustom.$error }"
          :error="$v.firstCustom.$error ? keyErrorMessage : ''"
          :placeholder="selectedTable.custom_field_first.name"
          @blur="$v.firstCustom.$touch"
        />
        <woot-input v-if="selectedTable.custom_field_second.name"
          v-model.trim="secondCustom"
          :label="selectedTable.custom_field_second.name"
          type="text"
          :class="{ error: $v.secondCustom.$error }"
          :error="$v.secondCustom.$error ? keyErrorMessage : ''"
          :placeholder="selectedTable.custom_field_second.name"
          @blur="$v.secondCustom.$touch"
        />
      </div>
      <div class="modal-footer">
        <woot-button class="bg-primary" :is-loading="isUpdating" :disabled="isButtonDisabled">
          {{ $t('ATTRIBUTES_MGMT.EDIT.UPDATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('ATTRIBUTES_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    selectedAttribute: {
      type: Object,
      default: () => {},
    },
    selectedTable: {
      type: Object,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companyName: '',
      clientCode: '',
      monthlyFee: '',
      firstCustom: '',
      secondCustom: '',
      status: '',
    };
  },
  validations: {
    companyName: { required },
    clientCode:{},
    monthlyFee:{},
    firstCustom:{},
    secondCustom:{},
    status:{},
  },
  computed: {
    pageTitle() {
      return `${this.$t('ATTRIBUTES_MGMT.EDIT.TITLE')} - ${
        this.selectedAttribute.company
      }`;
    },
    isButtonDisabled() {
      return this.$v.$invalid;
    },
  },
  mounted() {
    this.setFormValues();
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    setFormValues() {
      this.companyName = this.selectedAttribute.company;
      this.clientCode = this.selectedAttribute.client_code;
      this.monthlyFee = this.selectedAttribute.monthly_fee;
      this.firstCustom = this.selectedAttribute.custom_field_first;
      this.secondCustom = this.selectedAttribute.custom_field_second;
      this.status = this.selectedAttribute.status;
    },
    async editAttributes() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await this.$store.dispatch('attributes/update', {
          id: this.selectedAttribute.id,
          table_id: this.selectedTable.id,
          company: this.companyName,
          client_code: this.clientCode,
          monthly_fee: this.monthlyFee,
          custom_field_first: this.firstCustom,
          custom_field_second: this.secondCustom,
        });
        this.onClose();
      } catch (error) {
        console.error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.key-value {
  padding: 0 var(--space-small) var(--space-small) 0;
  font-family: monospace;
}
</style>
