import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import AttributeAPI from '../../api/attributes';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  indexTab: 0,
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getAttributes: _state => {
    return _state.records;
  },
  getIndexTabAttributes(_state) {
    return _state.indexTab;
  },
  getAttributesByModel: _state => attributeModel => {
    console.log(_state.records)
    if (attributeModel === 'group_attribute'){
      return _state.records
    }
    return _state.records.filter(
      record => record.attribute_model === attributeModel
    );
  },
};

export const actions = {
  get: async function getAttributesByModel({ commit }, index) {
    commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isFetching: true });
    try {
      var response
      if (index == 2){
        response = await AttributeAPI.getGroupAttributes();
      }
      else {
        response = await AttributeAPI.getAttributesByModel();
      }
      commit(types.SET_CUSTOM_ATTRIBUTE, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createAttribute({ commit }, {attributeType, ...attributeObj}) {
    commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isCreating: true });
    var response
    try {
    if(attributeType === 'group_table'){
      response = await AttributeAPI.postGroupAttributeTable(attributeObj);
    }
    else if( attributeType === 'group_attribute'){
      response = await AttributeAPI.postGroupAttributes(attributeObj);
    }
    else {
      response = await AttributeAPI.create(attributeObj);
    }
    const attributeWithType = {
      ...response.data,
      attribute_type: attributeType,
    };

    commit(types.ADD_CUSTOM_ATTRIBUTE, attributeWithType);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    console.log("Chegou Aqui")
    commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isUpdating: true });
    var response
    try {
      if(updateObj?.attributeType === 'group_table'){
        response = await AttributeAPI.updateGroupAttributesTable(id, updateObj);
      }
      else if(updateObj?.table_id){
        response = await AttributeAPI.updateGroupAttributes(id, updateObj);
      }else {
        response = await AttributeAPI.update(id, updateObj);
      }
      console.log('antes do commit')
      commit(types.EDIT_CUSTOM_ATTRIBUTE, response.data);
      console.log("Passou do commit")
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, data) => {
    commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isDeleting: true });
    try {
      if (data.attributeType === 'group_attribute'){
        console.log(data.id)
        await AttributeAPI.deleteGroupAttributes(data.id);
        commit(types.DELETE_CUSTOM_ATTRIBUTE, data.id);
      } else {
        await AttributeAPI.delete(data);
        commit(types.DELETE_CUSTOM_ATTRIBUTE, data);
      }
      
    } catch (error) {
      console.log(error)
      throw new Error(error);
    } finally {
      commit(types.SET_CUSTOM_ATTRIBUTE_UI_FLAG, { isDeleting: false });
    }
  },
  setTab: ({ commit }, index) => {
    commit(types.SET_INDEX_ATTRIBUTE, index);
  },
};

export const mutations = {
  [types.SET_CUSTOM_ATTRIBUTE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_INDEX_ATTRIBUTE](_state, index) {
    _state.indexTab = index;
  },
  [types.ADD_CUSTOM_ATTRIBUTE](_state, attribute) {
    if (attribute.attribute_type === 'group_attribute') {
      if (_state.records.length > 0) {
        if (!_state.records[0].group_attributes) {
          _state.records[0].group_attributes = [];
        }
        _state.records[0].group_attributes.push(attribute);
      }
    } else {
      MutationHelpers.create(_state, attribute);
    }
  },
  [types.SET_CUSTOM_ATTRIBUTE]: MutationHelpers.set,
  [types.EDIT_CUSTOM_ATTRIBUTE]: MutationHelpers.update,
  [types.DELETE_CUSTOM_ATTRIBUTE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
